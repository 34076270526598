import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.css";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import userKey from "./utils/randomUserKey";

(async () => {
  const LD_CLIENTSIDE_ID = process.env.REACT_APP_LD_CLIENTSIDE_ID;
  console.log(LD_CLIENTSIDE_ID);
  var key = userKey();
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENTSIDE_ID,
    context: {
      kind: "user",
      key: key,
    },
    options: {
      streaming: true,
    },
  });

  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <LDProvider>
            <App />
          </LDProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>
  );
})();
