import React from "react";
import "../styles/App.css";
import Camel from "../components/showCamel";

export const HomePage = () => (
  
    <div className="App">
      <header className="App-header">
        <Camel />
        <h1>Camel 24 Club</h1>
      </header>
    </div>
  
);
