import { withLDConsumer } from "launchdarkly-react-client-sdk";
import oneHumpedCamel from '../assets/img/black-silhouette-shadow-of-a-one-humped-camel.png';
import doubleHumpedCamel from '../assets/img/black-silhouette-shadow-of-a-double-humped-camel.png';

const camel = ({ flags }) => {

  return flags.showCamel ? (
    flags.spinCamel ? (
      <div>
        <img src={oneHumpedCamel} className="App-logo-left" alt="one-humped camel" />
      </div>
    ) : (
      <div>
        <img src={oneHumpedCamel} className="App-logo-right" alt="one-humped camel" />
      </div>
    )
  ) : (
    flags.spinCamel ? (
      <div>
        <img src={doubleHumpedCamel} className="App-logo-left" alt="double-humped camel" />
      </div>
    ) : (
      <div>
        <img src={doubleHumpedCamel} className="App-logo-right" alt="double-humped camel" />
      </div>
    )
  );

};

export default withLDConsumer()(camel);